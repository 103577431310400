@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@500&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:root {
  font-size: 62.5%; /* (62.5/100) * 16px = 10px */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'JetBrains Mono', monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

img{
  max-width: 100%;
}
